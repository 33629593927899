.context-info {
  padding: 0.3rem 0.6rem;
  border-radius: 3px;
  visibility: hidden;
  opacity: 0;
  background-color: rgba(16, 78, 165, 0.1);
  color: #235fb2;
  border: 1px solid rgba(11, 56, 119, 0.1);
  animation: fadeIn 250ms ease-out, boxshadowExpandBlue 800ms ease-out;
  animation-fill-mode: forwards;
  padding: 15px;
}

.context-error {
  padding: 0.3rem 0.6rem;
  border-radius: 3px;
  opacity: 0;
  background-color: rgba(196, 56, 84, 0.08);
  color: #ad324a;
  border: 1px solid rgba(156, 45, 67, 0.08);
  animation: fadeIn 250ms ease-out, boxshadowExpandRed 800ms ease-out;
  animation-fill-mode: forwards;
  padding: 15px;
}

.context-success {
  padding: 0.3rem 0.6rem;
  border-radius: 3px;
  opacity: 0;
  background-color: rgba(161, 205, 168, 0.2);
  color: #1b7742;
  border: 1px solid rgba(128, 187, 137, 0.2);
  animation: fadeIn 250ms ease-out, boxshadowExpandGreen 800ms ease-out;
  animation-fill-mode: forwards;
  padding: 15px;
}

.context-warn {
  padding: 0.3rem 0.6rem;
  border-radius: 3px;
  opacity: 0;
  background-color: rgba(255, 243, 205, 0.45);
  color: #8d6708;
  border: 1px solid rgba(255, 219, 103, 0.45);
  animation: fadeIn 250ms ease-out, boxshadowExpandYellow 800ms ease-out;
  animation-fill-mode: forwards;
  padding: 15px;
}
