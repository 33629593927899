/* This styling is here to remove arrows from the number input */

/* Chrome, Safari, Edge, Opera */
.gradeInputBoxForCanvas input::-webkit-outer-spin-button,
.gradeInputBoxForCanvas input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.gradeInputBoxForCanvas input[type='number'] {
  -moz-appearance: textfield;
}

/* Increase the contrast ratio for accessibility */
input::placeholder {
  color: #767676 !important;
}

/* Increase background visibility for modal */
.ui.dimmer {
  background-color: rgba(0, 0, 0, 0.5);
}
