.queue-item.item::before {
  display: inline-block;
  width: 30px;
}

.queue-item.canvas.item::before {
  content: url('canvas-logo.png');
  transform: rotate(-90deg) translate(-30px, 15px) scale(0.5, 0.5);
}

.queue-item.bcs.item::before {
  content: url('bcs-logo.jpeg');
  transform: rotate(-90deg) translate(-60px, 25px) scale(0.7, 0.7);
}
