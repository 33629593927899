.float-left {
  float: left;
}

.float-right {
  float: right;
}

.padding-right-none {
  padding-right: 0 !important;
}

.padding-left-none {
  padding-left: 0 !important;
}
