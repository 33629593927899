p.assignmentDetail {
  width: 100%;
  float: left;
}

.buttonIcon {
  position: relative;
}

.buttonIcon .buttonIconImage {
  position: absolute;
  top: 0;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  margin: auto;
  display: flex;
}

.buttonIcon.left .buttonIconImage {
  left: 0;
  border-radius: 5px 0 0 5px;
}

.buttonIcon.right .buttonIconImage {
  right: 0;
  border-radius: 0 5px 5px 0;
}

.buttonIcon.left .buttonIconContent {
  margin-left: 25px;
}

.buttonIcon.right .buttonIconContent {
  margin-right: 25px;
}

.buttonIcon .buttonIconImage i {
  vertical-align: middle;
  margin: auto;
  width: 36px;
}
